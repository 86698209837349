

import emailjs from 'emailjs-com';
emailjs.init('u2ryRAh2gSXzsfnB1');

const submitButton = document.querySelector('#myForm1 button[type="submit"]');

// Добавить обработчик события клика на кнопку отправки формы
submitButton.addEventListener('click', function(event){
  sendEmail(event); // передать объект event в функцию sendEmail
});

function sendEmail(event) {
    event.preventDefault(); // Предотвратить отправку формы вручную
   
    // Получите данные из формы
    var name = document.querySelector('.service-page__info-input[type="text"]').value;
    var service = document.querySelector('#html-select').value;
    var birthday = document.querySelector('.service-page__info-input[type="date"]').value;
    var email = document.querySelector('.service-page__info-input[type="email"]').value;
    var textt = document.querySelector('.service-page__info-input[type="textt"]').value;


// Проверка, чтобы поля были заполнены
if (email === '' || textt === '' || name == '' || birthday == '') {
  alert('Пожалуйста, заполните все поля формы.');
  return; // Выход из функции, если поля не заполнены
}

// Проверка, чтобы в поле email был введен правильный адрес
if (!validateEmail(email)) {
  alert('Пожалуйста, введите корректный адрес электронной почты.');
  return; // Выход из функции, если email некорректный
}

    // Параметры для отправки email
    var templateParams = {
        from_name: name,
        service: service,
        birthday: birthday,
        email: email,
        textt: textt
    };
  
    // Отправить email используя EmailJS
    emailjs.send('service_2ovfkgb', 'template_oirlbhh', templateParams)
      .then(function(response) {
          console.log('Email успешно отправлен!', response.status, response.text);
          alert('Заявка успешно отправлена! Ожидайте ответа.');
      }, function(error) {
          console.log('Ошибка отправки email:', error);
          alert('Произошла ошибка при отправке запроса. Пожалуйста, повторите попытку еще раз или позже.');
      });
  
    // Очистите значения полей формы после отправки
    document.getElementById('myForm1').reset();
}
function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}